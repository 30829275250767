import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import logo_waterLeakage from '../myPages/Home/sensor_icon/sensor_icon/waterleak_icon.svg';
import {
  faToggleOn,
  faHouse,
  faHistory,
  faCity,
  faArrowDown19,
  faHouseLaptop,
  faCloud,
  faBuilding,
  faUserPlus,
  faPenToSquare,
  faRectangleList,
  faToiletsPortable,
  faDoorOpen,
  faPersonWalkingDashedLineArrowRight,
  faPeopleGroup,
  faToiletPaper,
  faBoxesPacking,
  faFaucetDrip,
  faFire,
  faSmoking,
  faHandsBubbles,
  faTrashCan,
  faTv,
  faMicrochip,
  faPersonFalling,
  faGauge
} from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faBacterium } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logo_cubicle from '../myPages/Home/sensor_icon/sensor_icon/cubicle.svg';
import logo_waterLeakage from '../myPages/Home/sensor_icon/sensor_icon/waterleakage.svg';
import logo_urinal from '../myPages/Home/sensor_icon/sensor_icon/urinal.svg';
import logo_handPaper from '../myPages/Home/sensor_icon/sensor_icon/handpaper.svg';
import logo_IAQ from '../myPages/Home/sensor_icon/sensor_icon/IAQ_home.svg';
import logo_configSensor from './menuicons/configSensor.svg';
import { LineGraph } from '@styled-icons/entypo/LineGraph';
///////////////////
import { History } from '@styled-icons/material-rounded/History';
// import { faMicrochip } from '@fortawesome/free-solid-svg-icons';

let pages = [
  {
    page: 'PageHome',
    pagename: 'sidebar_home',
    parent: 'home',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouse} />
  },
  {
    page: 'PageCompany',
    pagename: 'sidebar_company',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faCity} />
  },
  {
    page: 'PageFloorOrder',
    pagename: 'sidebar_floor_order',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faArrowDown19} />
  },
  {
    page: 'PagePiDetail',
    pagename: 'sidebar_pi',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageLookupLiTe',
    pagename: 'sidebar_lite_viewer',
    parent: 'mains',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageMigrateLiTeCompProp',
    pagename: 'sidebar_lite_migrate',
    parent: 'mains',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageMigrateLiTeCompPropMass',
    pagename: 'sidebar_lite_migrate_mass',
    parent: 'mains',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageAddLiTe',
    pagename: 'sidebar_lite',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faCloud} />
  },
  {
    page: 'PageProperty',
    pagename: 'sidebar_property',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faBuilding} />
  },
  {
    page: 'PageUser',
    pagename: 'sidebar_user',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faUserPlus} />
  },

  {
    page: 'PageAddThermal',
    pagename: 'home_card_thermal',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonFalling} />
  },
  {
    page: 'PageAddGPIO',
    pagename: 'home_card_GPIO',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faMicrochip} />
  },

  {
    page: 'PageWhatsapp',
    pagename: 'sidebar_whatsapp_alert',
    parent: 'setup',
    advance: 0,
    icon: <FontAwesomeIcon icon={faWhatsapp} />
  },

  {
    page: 'PageRequestForm',
    pagename: 'sidebar_workform_form',
    parent: 'reportsys',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPenToSquare} />
  },
  {
    page: 'PageRequestOverview',
    pagename: 'sidebar_workform_overview',
    parent: 'reportsys',
    advance: 0,
    icon: <FontAwesomeIcon icon={faRectangleList} />
  },
  {
    page: 'PageCigarette',
    pagename: 'home_card_cigarette',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faSmoking} />
  },
  {
    page: 'PageCubicle',
    pagename: 'home_card_cubicle',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageCubicle_Flush',
    pagename: 'home_card_cubicle_flush',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageEmergencyDoor',
    pagename: 'home_card_emergency_door',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageMotionSensor',
    pagename: 'home_card_motion_sensor',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageIndicator',
    pagename: 'home_card_indicator',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageTemp',
    pagename: 'home_card_temp',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageDoor',
    pagename: 'home_card_door',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faDoorOpen} />
  },
  {
    page: 'PageEValve',
    pagename: 'sidebar_EValve',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToggleOn} />
  },
  {
    page: 'PageFireAlarm',
    pagename: 'home_card_fire',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faFire} />
  },
  {
    page: 'PageHandPaper',
    pagename: 'home_card_hand_paper',
    parent: 'device',
    advance: 0,
    icon: <img src={logo_handPaper} />
  },
  {
    page: 'PageIAQ',
    pagename: 'home_card_iaq',
    parent: 'device',
    advance: 0,
    icon: <img src={logo_IAQ} />
  },
  {
    page: 'PagePeopleCount',
    pagename: 'home_card_peoplecount',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
  },
  {
    page: 'PageQueue',
    pagename: 'home_card_queue',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faPeopleGroup} />
  },
  {
    page: 'PageRollPaper',
    pagename: 'home_card_roll_paper',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faToiletPaper} />
  },
  {
    page: 'PageRepeater',
    pagename: 'home_card_repeater',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageIOBoard',
    pagename: 'home_card_io_board',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faMicrochip} />
  },

  {
    page: 'PageGPIO',
    pagename: 'home_card_gpio',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faMicrochip} />
  },
  {
    page: 'PageThermal',
    pagename: 'home_card_thermal',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faPersonFalling} />
  },
  {
    page: 'PageStorage',
    pagename: 'home_card_storage',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faBoxesPacking} />
  },
  {
    page: 'PageUVC',
    pagename: 'home_card_uvc',
    parent: 'device',
    advance: 1,
    icon: (
      <div className="fa-layers fa-fw" style={{ margin: 'auto' }}>
        <FontAwesomeIcon icon={faBacterium} size="xs" />
        <FontAwesomeIcon icon={faBan} />
      </div>
    )
  },
  // {
  //   page: 'PageSmoke',
  //   pagename: 'home_card_smoke',
  //   parent: 'device',
  //   advance: 1,
  //   icon: <FontAwesomeIcon icon={faSmoking} />
  // },
  {
    page: 'PageSink',
    pagename: 'home_card_sink',
    parent: 'device',
    advance: 1,
    icon: <FontAwesomeIcon icon={faFaucetDrip} />
  },

  // {
  //   page: 'PageSmartToilet',
  //   pagename: 'sidebar_smartToilet',
  //   parent: 'device',
  //   advance: 0,
  //   icon: <FontAwesomeIcon icon={faRestroom} />
  // },
  {
    page: 'PageSoap',
    pagename: 'home_card_soap',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHandsBubbles} />
  },
  {
    page: 'PageTrashBin',
    pagename: 'home_card_trashBin',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faTrashCan} />
  },
  {
    page: 'PageToiletDisplaySetup',
    pagename: 'home_card_display',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faTv} />
  },
  {
    page: 'PageUrinal',
    pagename: 'home_card_urinal',
    parent: 'device',
    advance: 1,
    icon: <img src={logo_urinal} />
  },
  {
    page: 'PageWaterLeakage',
    pagename: 'sidebar_waterleakage',
    parent: 'device',
    advance: 0,
    icon: <img src={logo_waterLeakage} />
  },
  {
    page: 'PageWeight',
    pagename: 'home_card_weight',
    parent: 'device',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageCubicleLog',
    pagename: 'home_card_cubicle',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageEmergencyDoorLog',
    pagename: 'home_card_emergency_door',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageMotionSensorLog',
    pagename: 'home_card_motion_sensor',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageIndicatorLog',
    pagename: 'home_card_indicator',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageCubicleFlushLog',
    pagename: 'home_card_cubicle_flush',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageFallDetectionLog',
    pagename: 'home_card_thermal',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonFalling} />
  },
  {
    page: 'PageWaterLog',
    pagename: 'home_card_water_leakage',
    parent: 'activity_log',
    advance: 0,
    icon: <img src={logo_waterLeakage} />
  },
  {
    page: 'PageValveLog',
    pagename: 'sidebar_EValve',
    parent: 'activity_log',
    advance: 1,
    icon: <FontAwesomeIcon icon={faToggleOn} />
  },
  {
    page: 'PageBinLog',
    pagename: 'home_card_trashBin',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faTrashCan} />
  },
  {
    page: 'PageSoapLog',
    pagename: 'home_card_soap',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHandsBubbles} />
  },
  {
    page: 'PageHandLog',
    pagename: 'home_card_hand_paper',
    parent: 'activity_log',
    advance: 0,
    icon: <img src={logo_handPaper} />
  },
  {
    page: 'PageDoorLog',
    pagename: 'home_card_door',
    parent: 'activity_log',
    advance: 1,
    icon: <FontAwesomeIcon icon={faDoorOpen} />
  },
  {
    page: 'PageOfflineLiteLog',
    pagename: 'offline_lite',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageUVCLog',
    pagename: 'home_card_uvc',
    parent: 'activity_log',
    advance: 1,
    icon: <img src={logo_IAQ} />
  },
  {
    page: 'PagePeopleCountLog',
    pagename: 'home_card_peoplecount',
    parent: 'activity_log',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
  },
  {
    page: 'PagePlayground',
    pagename: 'Graph Demo',
    parent: 'analysis',
    advance: 0,
    icon: <LineGraph size="24" color="#ffffff" />
  },
  {
    page: 'PageAnalysisEValve',
    pagename: 'sidebar_EValve',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faToggleOn} />
  },
  {
    page: 'PageAnalysisCubicle',
    pagename: 'home_card_cubicle',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageAnalysisThermalFall',
    pagename: 'home_card_thermal',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonFalling} />
  },
  {
    page: 'PageAnalysisCubicleFlush',
    pagename: 'home_card_cubicle_flush',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faToiletsPortable} />
  },
  {
    page: 'PageAnalysisTrashBin',
    pagename: 'home_card_trashBin',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faTrashCan} />
  },
  {
    page: 'PageAnalysisPeopleCount',
    pagename: 'home_card_peoplecount',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
  },
  {
    page: 'PageAnalysisWaterLeakage',
    pagename: 'sidebar_waterleakage',
    parent: 'analysis',
    advance: 0,
    icon: <img src={logo_waterLeakage} />
  },
  {
    page: 'PageAnalysisSoap',
    pagename: 'home_card_soap',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHandsBubbles} />
  },
  {
    page: 'PageAnalysisSink',
    pagename: 'home_card_sink',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faFaucetDrip} />
  },
  {
    page: 'PageAnalysisRepeater',
    pagename: 'home_card_repeater',
    parent: 'analysis',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHouseLaptop} />
  },
  {
    page: 'PageAnalysisHandPaper',
    pagename: 'home_card_hand_paper',
    parent: 'analysis',
    advance: 0,
    icon: <img src={logo_handPaper} />
  },
  {
    page: 'PageAnalysisRollPaper',
    pagename: 'home_card_roll_paper',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faToiletPaper} />
  },
  {
    page: 'PageAnalysisIAQ',
    pagename: 'home_card_iaq',
    parent: 'analysis',
    advance: 0,
    icon: <img src={logo_IAQ} />
  },
  {
    page: 'PageAnalysisQueue',
    pagename: 'home_card_queue',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faPeopleGroup} />
  },
  {
    page: 'PageAnalysisUrinal',
    pagename: 'home_card_urinal',
    parent: 'analysis',
    advance: 1,
    icon: <img src={logo_urinal} />
  },
  {
    page: 'PageAnalysisCigarette',
    pagename: 'home_card_cigarette',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faSmoking} />
  },
  {
    page: 'PageAnalysisFireAlarm',
    pagename: 'home_card_fire',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faFire} />
  },
  {
    page: 'PageAnalysisDoor',
    pagename: 'home_card_door',
    parent: 'analysis',
    advance: 1,
    icon: <FontAwesomeIcon icon={faDoorOpen} />
  },

  {
    page: 'PageAnalysisReportPeopleCount',
    pagename: 'home_card_peoplecount',
    parent: 'report',
    advance: 0,
    icon: <FontAwesomeIcon icon={faPersonWalkingDashedLineArrowRight} />
  },
  {
    page: 'PageLiTeDetail',
    pagename: 'sidebar_lite_detail',
    parent: 'home_end',
    advance: 0,
    icon: <FontAwesomeIcon icon={faGauge} />
  },
  // {
  //   page: 'PageLiteHistory',
  //   pagename: 'sidebar_history',
  //   parent: 'home_end',
  //   advance: 0,
  //   icon: <FontAwesomeIcon icon={faHistory} />
  // },
  {
    page: 'PageLiteHistoryNew',
    pagename: 'sidebar_history_new',
    parent: 'home_end',
    advance: 0,
    icon: <FontAwesomeIcon icon={faHistory} />
  }
];
let findAccordingToCategories = (parentName) => {
  let returnPages = pages.filter((elem) => {
    return elem.parent === parentName;
  });
  returnPages.sort((itemA, itemB) => {
    return itemA.pagename == itemB.pagename
      ? 0
      : itemA.pagename > itemB.pagename
      ? 1
      : -1;
  });
  return returnPages;
};

let getAllPageTitle = () => {
  let PagePermissions = [];
  for (let page of Object.keys(pages)) {
    PagePermissions.push(pages[page].page);
  }
  return PagePermissions;
};

let setPagesAccessible = (rx_permission) => {
  localStorage.setItem('rx_permission', JSON.stringify(rx_permission));
  console.log('Seted setPagesAccessible', rx_permission);
};

let getPageAccessible = (pageName) => {
  let rx_permission = localStorage.getItem('rx_permission');
  if (rx_permission != '[object Object]')
    rx_permission = JSON.parse(rx_permission || '{}');
  else return 1;
  // console.log(
  //   'rx_permission[pageName]',
  //   rx_permission,
  //   pageName,
  //   rx_permission[pageName]
  // );
  return rx_permission[pageName] || 0;
};

export default {
  pages,
  findAccordingToCategories,
  getAllPageTitle,
  setPagesAccessible,
  getPageAccessible
};
